// const baseUrl = process.env.BASE_URL || 'http://localhost:5000'
// export const apiUrl = 'http://localhost:3001/api/send-encrypted-credentials';
// export const frontEndUrl = process.env.FRONTEND_URL || 'http://localhost:3000'
// export const personalAssetUrl = process.env.PERSONAL_ASSET_URL || 'http://localhost:3002'
// export const assetUrl = process.env.ASSET_URL || 'http://localhost:3004'
// export const sofwareUrl = process.env.SOFTWARE_URL || 'http://localhost:3006'
// export const serviceTrackerUrl = process.env.SOFTWARE_URL || 'https://servicetracker.fibiweb.com/fibi-sr'
// export const SecretPassUrl= process.env.SECRETPASS_URL || "http://localhost:3008"
// export default baseUrl;

const baseUrl = process.env.BASE_URL || 'https://dev-corporateportal.polussolutions.com'
export const frontEndUrl = process.env.FRONTEND_URL || 'https://dev-corporateportal.polussolutions.com'
export const personalAssetUrl = process.env.PERSONAL_ASSET_URL || 'https://dev-personalasset.polussolutions.com'
export const assetUrl = process.env.ASSET_URL || 'https://dev-asset.polussolutions.com'
export const sofwareUrl = process.env.SOFTWARE_URL || 'https://dev-software.polussolutions.com'
export const SecretPassUrl= process.env.SECRETPASS_URL || "https://dev-passsafe.polussolutions.com"
export default baseUrl;